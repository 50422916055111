


































































































































































































import { Component, Vue } from "vue-property-decorator";
import { RegionalPassport } from "@/models/Indicator";
import { Region } from "@/models/Region";
import {
  REGION_REQUEST,
  REGION_UPDATED,
  REGION_LOADED,
} from "@/store/actions/main";
import { Indicator } from "@/models/Indicator";
import {
  INDICATOR_REQUEST,
} from "@/store/actions/indicator";

@Component({})
export default class RegionalPassports extends Vue {
  private passports: RegionalPassport[] = [];
	private regions: any = [];
  private form: RegionalPassport = new RegionalPassport();
  private passportsHeaders = [
    {
      text: "Наименование",
      value: "title",
      align: "start",
      width: "35%",
    },
    {
      text: "Регион",
      value: "region_id",
      align: "start",
      width: "5%",
    },
    {
      text: "Файлы",
      value: "attachments.length",
      align: "center",
      sortable: false,
      width: "1%",
    },
    {
      text: "Действия",
      value: "actions",
      align: "center",
      sortable: false,
      width: "10%",
    },
  ];
  private loading: boolean = false;
  private itemId: number = 0;
  private currentItem: any = {} as any;
  //Snackbar
  private messageText = "Не удалось выполнить";
  private messageColor = "red";
  private message = false;
  private search = "";
  private itemToDelete: RegionalPassport = new RegionalPassport();
  private deleteDialog: boolean = false;
  private deleteSnackbar: boolean = false;
  private deleteDisabled: boolean = false;
  //options
  private tablePage = 1;
  private tablePageCount = 0;
  private itemsPerPage = 15;
  //Initialise methods
  private initIfReady() {
    //return true if started to init self.
    let needReload = [] as boolean[];
    needReload.push(
      this.needReload(
        this.$store.getters.INDICATOR_TABLE,
        this.$store.getters.INDICATOR_LOADED
      )
    );
		needReload.push(
      this.needReload(
        this.$store.getters.REGION_TABLE,
        this.$store.getters.REGION_LOADED
      )
    );
    for (let i = 0; i < needReload.length; i++) {
      if (needReload[i] == true) {
        return false;
      }
    }
    console.log("TRUE INIT SELF");
    this.initSelf();
    return true;
  }
  private needReload(table, updated) {
    /*
		data is a table loading from request.
		updated is flag that is false when table has old data and need to be reloading.
		return true if need reload.
		*/
    if (table == null || !updated) return true;
    return false;
  }
  private tryToInitSelf() {
    this.loading = true;
    //const data = this.getParams();
    if (this.initIfReady()) return;

    if (
      this.needReload(
        this.$store.getters.INDICATOR_TABLE,
        this.$store.getters.INDICATOR_LOADED
      )
    ) {
      this.$store.dispatch(INDICATOR_REQUEST).then(() => {
        this.initIfReady();
      });
    }
		if (
      this.needReload(
        this.$store.getters.REGION_TABLE,
        this.$store.getters.REGION_LOADED
      )
    ) {
      this.$store.dispatch(REGION_REQUEST).then(() => {
        this.initIfReady();
      });
    }
  }
	private initSelf() {
    console.log("INIT SELF");
    //this.passports = this.$store.getters.INDICATOR_TABLE.data;
		this.regions = this.$store.getters.REGION_TABLE.data;
		//this.passports = JSON.parse(this.toString(localStorage.getItem("regional")));
    this.passports = this.currentItem.regional_passports;
    this.loading = false;
    console.log("loading");
  }
  //Methods
	private toString(str) {
		return str as string;
	}
  private isDeleting(id) {
    if (this.itemToDelete.id == id) return true;
    return false;
  }
  private cleanDeleting() {
    this.itemToDelete = new Indicator();
  }

  private toCreate() {
		this.$router.push({ name: "IndicatorsRegionalCreate" });
  }
  private toEdit(item) {
    console.log("item: ", item);
    const parsed = JSON.stringify(item);
    localStorage.setItem("regionalPassport", parsed);
    this.$router.push({ name: "IndicatorsRegionalUpdate", params: { id: item.indicator_id, passport_id: item.id } });
  }
  
  //Methods
  // private deleteItem(id) {
  //   this.$store.dispatch(INDICATOR_DELETE, id).then(() => {
  //     this.tryToInitSelf();
  //   });
  // }

  private deleteItem(item: Indicator, confirmation: boolean) {
    this.itemToDelete = item;
    if (confirmation) {
      this.deleteDialog = true;
    } else {
      console.log(item);
      console.log("Delete!");
      // this.$store.dispatch(INDICATOR_DELETE, item.id).then(() => {
      //   this.tryToInitSelf();
      //   this.deleteSnackbar = true;
      //   this.itemToDelete = new Indicator();
      //   this.deleteDisabled = false;
      // });
      this.deleteDialog = false;
    }
  }
	private getRegion(id){
    for(let i = 0; i < this.regions.length; i++){
      if(id == this.regions[i].id)
        return this.regions[i].title
    }
  }
  //Hooks
	private created(){
		// if(localStorage.getItem("regionalIndicatorTitle")){
		// 	let title = localStorage.getItem("regionalIndicatorTitle");
		// 	this.$router.currentRoute.meta.breadcrumb[2].text = title;
		// }
    this.$store.dispatch(INDICATOR_REQUEST).then(() => {
      let indicators = this.$store.getters.INDICATOR_TABLE.data;
      let data = this.toString(localStorage.getItem("currentIndicator"));
      let currentIndicator = JSON.parse(data);
      this.currentItem = currentIndicator;
      for(let i = 0; i < indicators.length; i++){
        if(indicators[i].id == currentIndicator.id){
          this.$router.currentRoute.meta.breadcrumb[2].text = indicators[i].indicator_title;
          this.$router.replace({name: "IndicatorsRegionalPassports", params: {id: currentIndicator.id}})
          this.currentItem = indicators[i];
          break;
        }
      }
      this.tryToInitSelf();
    });
	}
  private mounted() {
    
  }
}
